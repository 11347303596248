<template>
  <div class="row">
    <ServiceNavbar
      :name="'Danh sách dịch vụ'"
      :type="'services'"
    />
    <div class="telemedicine-management pr-3 mt-3">
      <div class="row pl-3">
        <div class="col-sm-12">
          <div class="row justify-content-lg-between">
            <div class="col-12 col-lg-auto mb-1">
              <div class="d-flex">
                <input
                  class="form-control mr-sm-2"
                  type="search"
                  placeholder="Nhập tên dịch vụ cần tìm kiếm"
                  aria-label="Search"
                  v-model="search"
                  @click.enter="searchService()"
                  @click.delete="searchServiceDelete()"
                >
                <button
                  class="btn bg-pri bd-pri text-white my-2 my-sm-0"
                  @click="searchService()"
                >Tìm kiếm</button>
              </div>
            </div>
            <div class="col-12 col-lg-auto mb-1">
              <div class="d-flex h-100">
                <button
                  class="btn h-100 bg-pri bd-pri text-white my-2 my-sm-0 btn-icon-add-sm"
                  @click="onAddService()"
                >Thêm dịch vụ</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card radius-10 pt-4 pl-4 pr-4 border-0">
        <div class="table-responsive">
          <table class="table table-vcenter table-mobile-md card-table table-scroll">
            <thead>
              <tr class="d-sm-flex">
                <th class="col-sm-1">ID</th>
                <th class="col-sm-2">CSYT</th>
                <th class="col-sm-2">Tên dịch vụ</th>
                <th class="col-sm-4">Mô tả ngắn</th>
                <th class="col-sm-1 text-sm-center">Mobile</th>
                <th class="col-sm-1 text-sm-center">Web</th>
                <th class="col-sm-2">Giá tiền</th>
              </tr>
            </thead>
            <tbody
              v-if="services && services.count && services.data"
              class="h-100"
            >
              <tr
                v-for="service in services.data"
                :key="service.id"
                class="d-sm-flex mt-2 mb-2"
                style="cursor: pointer;"
                :class="service.status === 1 ? '' : 'table-tr'"
                @click="onUpdateService(service)"
              >
                <td class="col-sm-1">{{service && service.id}}</td>
                <td class="col-sm-2">{{service && service.clinic_name}}</td>
                <td class="col-sm-2">{{service && service.name}}</td>
                <td class="col-sm-4">{{service && service.desc_short}}</td>
                <td
                  class="col-sm-1 text-sm-center"
                  data-title="Trạng thái"
                >
                  <span v-if="service">{{service.status === 1 ? 'Hiện' : 'Ẩn'}}</span>
                </td>
                <td
                  class="col-sm-1 text-sm-center"
                  data-title="Trạng thái"
                >
                  <span v-if="service">{{service.state === 1 ? 'Hiện' : 'Ẩn'}}</span>
                </td>
                <td class="col-sm-2">{{service && service.price ? formatPrice(service.price) : 'Liên hệ'}} {{service.price ?  (service.currency || 'VNĐ') : ''}}</td>
              </tr>
            </tbody>
          </table>
          <Pagination
            v-show="services && services.count"
            :items="services"
            :current_page="current_page"
            @onRefresh="getService"
          ></Pagination>
        </div>
        <div
          v-if="services && !services.count"
          class="row"
        >
          <div class="col-sm-4 offset-sm-4 pt-3 pb-5 text-center">
            <img
              src="../../../public/assets/images/not-found-search.png"
              class="img-responsive p-0"
              alt=""
            >
            <p class="robo-16-400 text-black">{{search ? 'HODO không tìm thấy dịch vụ mà bạn tìm' : 'Chưa có dịch vụs nào!' }}</p>
          </div>
        </div>
      </div>
    </div>
    <ModalService
      :edit="edit"
      @onSubmitAddService="onSubmitAddService"
      @onSubmitUpdateService="onSubmitUpdateService"
    ></ModalService>
  </div>
</template>
<script>
import Pagination from '../../components/Pagination'
import ModalService from '../../components/Cms/ModalService.vue'
import ServiceNavbar from '../../components/Services/ServiceNavbar.vue'
export default {
  name: 'ServiceListing',
  components: { Pagination, ModalService, ServiceNavbar },
  data () {
    return {
      services: null,
      loading: false,
      params: {
        limit: 10,
        search: '',
        sort: 'created_at',
        sort_by: 'desc'
      },
      search: '',
      current_page: 0,
      page: 1,
      edit: null
    }
  },
  mounted () {
    this.page = this.$route.query.page ? this.$route.query.page : 1
    this.getService()
  },
  methods: {
    async getService () {
      try {
        let self = this
        self.current_page = parseInt(self.$route.query.page) || 1
        self.params = {
          limit: 10,
          search: self.search,
          sort: 'created_at',
          sort_by: 'desc',
          page: self.current_page
        }
        const result = await self.$rf.getRequest('AdminRequest').getServices(self.params)
        self.services = result.data
        self.current_page = result.data.current_page
      } catch (e) {
        console.log(e)
      } finally {

      }
    },
    formatPrice (num) {
      if (!num) return 'Liên hệ'
      return num.toLocaleString('vi')
    },
    showModalService (show) {
      window.$('#modal--service').modal(show ? 'show' : 'hide')
    },
    onAddService () {
      let self = this
      self.edit = null
      self.showModalService(true)
    },
    onUpdateService (service) {
      let self = this
      self.edit = service
      self.showModalService(true)
    },
    async onSubmitAddService (data) {
      let self = this
      try {
        await self.$rf.getRequest('AdminRequest').postServices(data).then(res => {
          self.getService()
          self.showModalService(false)
        })
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    async onSubmitUpdateService (id, data) {
      let self = this
      try {
        // statements
        await self.$rf.getRequest('AdminRequest').postServicesUpdate(id, data).then(res => {
          self.getService()
          self.showModalService(false)
        })
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    searchService () {
      let self = this
      self.getService()
    },
    searchServiceDelete () {
      let self = this
      if (self.search) {
        self.getService()
      }
    }
  }
}
</script>
<style scoped></style>