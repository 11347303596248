<template>
	<div class="row">
		<div class="telemedicine-management pr-3">
			<div class="row pl-3">
				<div class="col-12">
					<div class="d-flex py-1 align-items-center">
						<img src="../../../public/assets/images/icon/request.svg" alt="" class="mr-2"><span class="txt-pri page-title">Yêu cầu</span>
					</div>
				</div>
				<div class="col-sm-12">
					<div class="row">
						<div class="col-12 col-lg-6 mb-1">
							<div class="row">
								<div class="col-auto">
									<div class="btn-group" role="group" aria-label="Basic example">
										<button type="button" class="btn h-100" :class="type === 1 ? 'btn--blue' : 'btn--babyblue'" @click="changeType(1)">Hôm nay</button>
										<button type="button" class="btn h-100" :class="type === 2 ? 'btn--blue' : 'btn--babyblue'" @click="changeType(2)">Tuần</button>
										<button type="button" class="btn h-100" :class="type === 3 ? 'btn--blue' : 'btn--babyblue'" @click="changeType(3)">Tháng</button>
										<button type="button" class="btn h-100" :class="type === 4 ? 'btn--blue' : 'btn--babyblue'" @click="changeType(4)">Quý</button>
                    <button type="button" class="btn h-100" :class="type === 5 ? 'btn--blue' : 'btn--babyblue'" @click="changeType(5)">Tất cả</button>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 col-lg-6 mb-1">
							<div align="right" class="row">
								<div class="col-sm-6">
									<div class="dropdown">
										<button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<div>
												<div><small class="text-primary">Trạng thái yêu cầu</small></div>
												<div>
													<span v-if="status === 0">Tất cả</span>
													<span v-if="status === 1">Mới tạo</span>
													<span v-if="status === 2">BN chưa accept lịch</span>
													<span v-if="status === 3">BN chưa thanh toán</span>
													<span v-if="status === 4">Đang trong cuộc gọi</span>
													<span v-if="status === 5">Cuộc gọi thành công</span>
													<!-- <span v-if="status === 6">Cuộc gọi fail</span> -->
													<!-- <span v-if="status === 7">BS từ chối lịch hẹn</span> -->
													<span v-if="status === 8">BN từ chối lịch hẹn</span>
													<span v-if="status === 9">Không tham gia</span>
													<span v-if="status === 10">BS thay đổi lịch hẹn</span>
													<span v-if="status === 11">Kết thúc</span>
												</div>
											</div>
										</button>
										<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
											<a class="dropdown-item" href="javascript:void(0)" @click="selectStatus(0)">Tất cả</a>
											<a class="dropdown-item" href="javascript:void(0)" @click="selectStatus(1)">Mới tạo</a>
											<a class="dropdown-item" href="javascript:void(0)" @click="selectStatus(2)">BN chưa accept lịch</a>
											<a class="dropdown-item" href="javascript:void(0)" @click="selectStatus(3)">BN chưa thanh toán</a>
											<a class="dropdown-item" href="javascript:void(0)" @click="selectStatus(4)">Đang trong cuộc gọi</a>
											<a class="dropdown-item" href="javascript:void(0)" @click="selectStatus(5)">Cuộc gọi thành công</a>
											<!-- <a class="dropdown-item" href="javascript:void(0)" @click="selectStatus(6)">Cuộc gọi fail</a> -->
											<!-- <a class="dropdown-item" href="javascript:void(0)" @click="selectStatus(7)">BS từ chối lịch hẹn</a> -->
											<a class="dropdown-item" href="javascript:void(0)" @click="selectStatus(8)">BN từ chối lịch hẹn</a>
											<a class="dropdown-item" href="javascript:void(0)" @click="selectStatus(9)">Không tham gia</a>
											<a class="dropdown-item" href="javascript:void(0)" @click="selectStatus(10)">BS thay đổi lịch hẹn</a>
											<a class="dropdown-item" href="javascript:void(0)" @click="selectStatus(11)">Kết thúc</a>
										</div>
									</div>
								</div>
								<div class="col-sm-6"><button class="btn bg-pri bd-pri text-white h-100 w-100" style="font-size: 18px; font-weight: 700;" @click="filterRequest()">Lọc dữ liệu</button></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card radius-10 pt-2 pl-4 pr-4 border-0">
				<div class="table-responsive">
					<table class="table table-vcenter table-mobile-md card-table table-scroll">
						<thead>
							<tr>
								<th>Thông tin bệnh nhân</th>
								<th>Thời gian gửi</th>
								<th>Tới bác sĩ</th>
								<th>Dịch vụ</th>
								<th>Hoá đơn</th>
								<th>Trạng thái</th>
								<th>Nội dung</th>
							</tr>
						</thead>
						<tbody v-if="requests && requests.count && requests.data" :class="requests && requests.count >=9 ? 'h-100' : ''">
							<tr v-for="request in requests.data" :key="request.id" class="table-tr mt-2">
								<td data-label="Thông tin bệnh nhân" class="table-td-name-avatar">
									<div class="d-flex py-1 align-items-center">
										<div class="ml-1" style="min-width: 40px;">
											<span v-if="hasAvatar(request)" class="avatar avatar-sm avatar-rounded" :style="'background-image: url('+getImageURL(request.person.user.avatar)+')'"></span>
											<span v-if="!hasAvatar(request)" class="avatar avatar-sm avatar-rounded bg-secondary text-white">
												{{ request && request.person && request.person.name ? request.person.name.charAt(0) : '' }}
											</span>
										</div>
										<div class="flex-fill">
											<p class="p-0 m-0">{{request && request.person && request.person.name ? request.person.name : ''}}</p>
											<small class="text-black-50">{{request && request.person && request.person.phone ? request.person.phone : ''}}</small>
										</div>
									</div>
								</td>
								<td data-label="Thời gian gửi" v-html="request ? request.created_at ? formatTimeDDMMYYYYver2(request.created_at)  : '' : ''"></td>
								<td data-label="Tới bác sĩ">{{request && request.to && request.to.name ? request.to.name : ''}} <br> <small class="txt-pri">{{request && request.to && request.to.code ? request.to.code : ''}}</small></td>
								<td data-label="Dịch vụ">
                  <div class="d-flex py-1 align-items-center">
                    <div class="ml-1" style="min-width: 40px;">
                      <span class="d-flex align-items-center" v-if="request && request.appointment_latest && request.appointment_latest.method === 1">
                        <span class="avatar avatar-rounded icon-walking mr-1 d-lg-none d-xl-block"></span>
                      </span>
                      <span class="d-flex align-items-center" v-if="request && request.appointment_latest && request.appointment_latest.method === 2">
                        <span class="avatar avatar-rounded bg--primary icon-video mr-1 d-lg-none d-xl-block"></span>
                      </span>
                    </div>
                    <div class="flex-fill">
                      <span v-html="request ? request.appointment_latest ? formatTime(request.appointment_latest.start_time)  : '' : ''"></span> 
                      <span v-if="request && request.appointment_latest"><br><span class="text-center">-</span><br></span>
                      <span v-html="request ? request.appointment_latest ? formatWeekdayTime(request.appointment_latest.end_time)  : '' : ''"></span>
                    </div>
                  </div>
								</td>
								<td data-label="Hoá đơn">
									{{request && request.appointment_latest && request.appointment_latest.invoice ? request.appointment_latest.invoice.code : ''}} <br> <small :class="request && request.appointment_latest && request.appointment_latest.invoice ? getInvoiceStatus(request.appointment_latest.invoice.status).class : 'txt-pri'">{{request && request.appointment_latest && request.appointment_latest.invoice ? getInvoiceStatus(request.appointment_latest.invoice.status).text : ''}}</small>
								</td>
								<td data-label="Trạng thái">
									<span :class="`${getStatus(checkStatusRequest(request)).class}`" style="border-radius: 5px;">
										<span style="padding: 10px 5px;">{{getStatus(checkStatusRequest(request)).text}}</span>
									</span>
								</td>
								<td data-label="Nội dung" class="text-nowrap text-truncate" :title="request && request.content ? request.content : ''">{{request && request.content ? request.content : ''}}</td>
							</tr>
						</tbody>
					</table>
					<Pagination v-show="requests && requests.count" v-if="requests" :items="requests" :current_page="current_page" @onRefresh="getRequest"></Pagination>
				</div>
				<div v-if="requests && !requests.count" class="row">
					<div class="col-sm-4 offset-sm-4 pt-3 pb-5 text-center">
						<img src="../../../public/assets/images/not-found-search.png" class="img-responsive p-0" alt="">
						<p class="robo-16-400 text-black">Không có yêu cầu nào</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
  import appUtils from '../../utils/appUtils'
  import Pagination from '../../components/Pagination'

  export default {
    name: 'RequestListAdmin',
    components: {Pagination},
    data () {
      return {
        requests: null,
        current_page: 1,
        search: '',
        params: {
          limit: 20,
          page: 1,
          start_time: '',
          end_time: ''
        },
        type: 1,
        status: 0,
        get_request: ''
      }
    },
    mounted () {
      let self = this
      self.getRequest()
      self.get_request = setInterval(() => {
        self.getRequest()
      }, 120000)
    },
    beforeRouteLeave (to, from, next) {
      clearInterval(this.get_request)
      next()
    },
    methods: {
      async getRequest () {
        let self = this
        self.current_page = parseInt(self.$route.query.page) || 1
        self.params.page = self.current_page
        self.params.sort = 'created_at'
        self.params.sort_by = 'desc'
        if (self.type === 1) {
          self.params.start_time = window.moment(new Date()).startOf('day').format('HH:mm DD-MM-YYYY')
          self.params.end_time = window.moment(new Date()).endOf('day').format('HH:mm DD-MM-YYYY')
        } else if (self.type === 2) {
          self.params.start_time = window.moment(new Date()).startOf('week').format('HH:mm DD-MM-YYYY')
          self.params.end_time = window.moment(new Date()).endOf('week').format('HH:mm DD-MM-YYYY')
        } else if (self.type === 3) {
          self.params.start_time = window.moment(new Date()).startOf('month').format('HH:mm DD-MM-YYYY')
          self.params.end_time = window.moment(new Date()).endOf('month').format('HH:mm DD-MM-YYYY')
        } else if (self.type === 4) {
          self.params.start_time = window.moment(new Date()).startOf('quarter').format('HH:mm DD-MM-YYYY')
          self.params.end_time = window.moment(new Date()).endOf('quarter').format('HH:mm DD-MM-YYYY')
        } else if (self.type === 5) {
          self.params.start_time = null
          self.params.end_time = null
        }
        if (self.status !== 0) {
          if (self.status === 1) {
            self.params.appt_id = null
            self.params.ticket_id = null
            self.params.statuses = [2]
            let resp = await self.$rf.getRequest('AdminRequest').getRequest(self.params)
            self.requests = resp.data
          } else if (self.status === 2) {
            // self.params.statuses = [4]
            self.params.statuses = ''
            self.params.appt_id = null
            self.params.ticket_id = null
            let resp = await self.$rf.getRequest('AdminRequest').getRequestApptPending(self.params)
            self.requests = resp.data
          } else if (self.status === 3) {
            // self.params.statuses = [4]
            self.params.statuses = ''
            self.params.appt_id = null
            self.params.ticket_id = null
            let resp = await self.$rf.getRequest('AdminRequest').getRequestInvoiceUnpaid(self.params)
            self.requests = resp.data
          } else if (self.status === 4) {
            self.params.statuses = ''
            self.params.appt_id = null
            self.params.ticket_id = null
            let resp = await self.$rf.getRequest('AdminRequest').getRequestCalling(self.params)
            self.requests = resp.data
          } else if (self.status === 5) {
            self.params.statuses = ''
            self.params.appt_id = null
            self.params.ticket_id = null
            let resp = await self.$rf.getRequest('AdminRequest').getRequestCallCompleted(self.params)
            self.requests = resp.data
          } else if (self.status === 6) {
            self.params.statuses = ''
            self.params.appt_id = null
            self.params.ticket_id = null
            let resp = await self.$rf.getRequest('AdminRequest').getRequestCallFailed(self.params)
            self.requests = resp.data
          } else if (self.status === 7) {
            self.params.statuses = ''
            self.params.appt_id = null
            self.params.ticket_id = null
            let resp = await self.$rf.getRequest('AdminRequest').getRequestDoctorRejected(self.params)
            self.requests = resp.data
          } else if (self.status === 8) {
            self.params.statuses = ''
            self.params.appt_id = null
            self.params.ticket_id = null
            let resp = await self.$rf.getRequest('AdminRequest').getRequestPatientCancelled(self.params)
            self.requests = resp.data
          } else if (self.status === 9) {
            self.params.appt_id = null
            self.params.ticket_id = null
            let resp = await self.$rf.getRequest('AdminRequest').getRequestCallNotJoin(self.params)
            self.requests = resp.data
          } else if (self.status === 10) {
            self.params.appt_id = null
            self.params.ticket_id = null
            let resp = await self.$rf.getRequest('AdminRequest').getRequestApptChanged(self.params)
            self.requests = resp.data
          } else if (self.status === 11) {
            self.params.statuses = [6]
            self.params.appt_id = null
            self.params.ticket_id = null
            let resp = await self.$rf.getRequest('AdminRequest').getRequest(self.params)
            self.requests = resp.data
          }
        } else {
          self.params.statuses = ''
          self.params.appt_id = null
          self.params.ticket_id = null
          let resp = await self.$rf.getRequest('AdminRequest').getRequest(self.params)
          self.requests = resp.data
        }
      },
      hasAvatar (request) {
        return request && request.person && request.person.user && request.person.user.avatar
      },
      getImageURL (avatar) {
        return appUtils.getImageURL(avatar)
      },
      selectStatus (type) {
        this.status = type
      },
      filterRequest () {
        let self = this
        self.$router.replace({query: {page: 1}}).catch(() => {})
        this.getRequest()
      },
      formatWeekdayTimev2 (date_time) {
        let curr_week = window.moment().week()
        let date_time_week = window.moment(date_time).week()
        if (curr_week === date_time_week) {
          return window.moment(date_time).fromNow()
        } else {
          var split_time = window.moment(date_time).format('DD/MM/YYYY HH:mm ').split(' ')
          var str_time = split_time[1] + '<br><small class="robo-12-400 text-muted">' + split_time[0] + '</small>'
          return str_time
        }
      },
      formatTime (date_time) {
        var split_time = window.moment(date_time).format('HH:mm')
        return split_time
      },
      formatWeekdayTime (date_time) {
        var split_time = window.moment(date_time).format('DD/MM/YYYY HH:mm ').split(' ')
        var str_time = split_time[1] + '<br><small class="robo-12-400 text-muted">' + split_time[0] + '</small>'
        return str_time
      },
      getStatus (stt) {
        let statuses = {}
        statuses[1] = {text: 'Mới', class: 'text--red bg--red-corl'}
        statuses[2] = {text: 'Đang xử lý', class: 'text--green bg--green-corl'}
        statuses[3] = {text: 'Yêu cầu chuyển đến', class: 'text--purple bg--purple'}
        statuses[4] = {text: 'BN không đồng ý', class: 'text--orange bg--yellow-corl'}
        statuses[5] = {text: 'Bác sĩ từ chối', class: 'text--yellow bg--yellow-corl'}
        statuses[6] = {text: 'Kết thúc yêu cầu', class: 'text--red bg--red-corl'}
        statuses[7] = {text: 'Đã chuyển', class: 'text--purple bg--purple'}
        statuses[8] = {text: 'Có lịch hẹn', class: 'txt-pri bg--info'}
        statuses[9] = {text: 'Có chỉ dẫn', class: 'text--orange bg--yellow-corl'}
        return stt ? statuses[stt] : statuses[1]
      },
      getInvoiceStatus (stt) {
        let statuses = {}
        statuses[1] = {text: 'Chưa thanh toán', class: 'text--red'}
        statuses[2] = {text: 'Chờ xác nhận', class: 'text--yellow'}
        statuses[3] = {text: 'Đã thanh toán', class: 'text--green'}
        return stt && statuses[stt] ? statuses[stt] : statuses[1]
      },
      checkApptNow (request) {
        let self = this
        if (request) {
          let datetime_now = new Date()
          if (request.appointment) {
            return self.formatTimeDDMMYYYY(request.appointment.end_time) >= self.formatTimeDDMMYYYY(datetime_now)
          } else {
            return false
          }
        } else {
          return false
        }
      },
      checkStatusRequest (request) {
        let self = this
        if (request) {
          let datetime_now = new Date()
          if (request.appt_pending) {
            if (self.formatTimeDDMMYYYY(request.appt_pending.end_time) >= self.formatTimeDDMMYYYY(datetime_now)) {
              return 8
            } else {
              return self.checkStatusRequestV2(request)
            }
          } else {
            return self.checkStatusRequestV2(request)
          }
        } else {
          return 1
        }
      },
      formatTimeDDMMYYYY (datetime) {
        return window.moment(datetime).format('HH:mm DD-MM-YYYY')
      },
      formatTimeDDMMYYYYver2 (datetime) {
        return window.moment(datetime).format('DD-MM-YYYY HH:mm')
      },
      checkStatusRequestV2 (request) {
        let self = this
        if (request.status !== 1 && request.status !== 2 && request.status !== 3) {
          return request.status
        } else {
          if (request.status === 3) {
            if (self.checkTransfer(request.from_id, request.to_id)) {
              return 7
            } else {
              return 3
            }
          } else if (request.status === 1 || request.status === 2) {
            if (request.ticket_patient_pending) {
              return 9
            } else {
              return request.status
            }
          } else {
            return request.status
          }
        }
      },
      checkTransfer (id_from, id_to) {
        if (id_from && id_to) {
          var user = appUtils.getLocalUser().doctor ? appUtils.getLocalUser().doctor : ''
          if (user && id_to === user.id) {
            return false
          } else {
            return true
          }
        } else {
          return true
        }
      },
      changeType (type) {
        this.type = type
        if (this.$route.path === '/admin/request-list') {
          this.$router.replace({query: {page: 1}}).catch(() => {})
          this.$router.push({path: '/admin/request-list'}).catch(() => {})
        }
        this.getRequest()
      }
    }
  }
</script>
<style scoped>
	.icon-video {
    background-image: url('../../../public/assets/images/icon/white_video.svg'); 
    background-repeat: no-repeat;
    background-size: 25px 25px;
    background-color: #20419B;
    background-origin: center;
    background-position: center;
    height: 36px; 
    width: 36px;
  }
  .icon-walking {
    background-image: url('../../../public/assets/images/icon/white-walking.svg'); 
    background-repeat: no-repeat;
    background-size: 25px 25px;
    background-color: #0E7C71;
    background-origin: center;
    background-position: center;
    height: 36px; 
    width: 36px;
  }
</style>