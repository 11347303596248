<template>
  <div class="row">
    <div class="telemedicine-management pr-3 pt-3">
      <div class="row pl-3">
        <div class="col-6">
          <div class="d-flex py-1 align-items-center">
            <img src="https://img.icons8.com/ios/50/20419B/environment-care.png" /><span class="txt-pri page-title">Chỉ số</span>
          </div>
        </div>
        <div class="col-6 mb-1">
          <div class="d-flex justify-content-end">
                <button
                  class="input-group-text btn btn-l btn-primary"
                  @click="showAdd(true)"
                >Thêm mới +</button>
          </div>
        </div>
      </div>
      <div class="card radius-10 pt-4 pl-4 pr-4 border-0">
        <div class="d-flex justify-content-end mb-3">
          <select
            name=""
            id=""
            v-model="sort"
            class="form-select border mr-3"
            @change="getStats()"
          >
            <option value="updated_at">Ngày cập nhật</option>
            <option value="created_at">Ngày tạo</option>
          </select>
          <select
            name=""
            id=""
            v-model="sort_by"
            class="form-select border mr-3"
            @change="getStats()"
          >
            <option value="desc">Mới nhất trước</option>
            <option value="asc">Cũ nhất trước</option>
          </select>
            <div class="input-group">
                <input
                  class="form-control h-100 border"
                  type="text"
                  v-model="search"
                  @keyup.enter="onSearch()"
                  @keyup.delete="onSearchDelete()"
                >
                <button
                  class="input-group-text btn btn-light"
                  @click="onSearch()"
                >Tìm kiếm</button>
            </div>
        </div>
        <table class="table table-vcenter table-mobile-md card-table">
          <thead>
            <tr>
              <th>Tên chỉ số</th>
              <th>Code</th>
              <th>Đơn vị</th>
              <th>Cơ sở y tế</th>
              <th>Ngày tạo</th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="stats && stats.data && !loading">
            <tr
              v-for="stat in stats.data"
              :key="stat.id"
            >
              <td data-title="Tên chỉ số">
                <span>{{stat.name}}</span>
              </td>
              <td data-title="Code">
                <span>{{stat.code}}</span>
              </td>
              <td data-title="Đơn vị">
                <span>{{stat.unit}}</span>
              </td>
              <td data-title="Cơ sở y tế">
                <span v-if="stat.clinic">{{stat.clinic.name}}</span>
              </td>
              <td data-title="Ngày tạo">
                <span>{{stat && stat.created_at ? formatDDMMYYYY(stat.created_at) : ''}}</span>
              </td>
              <td>
                <button class="btn btn-sm bg-pri bd-pri text-white mr-2" @click="onEdit(stat)">Chỉnh sửa</button>
                <button class="btn btn-sm mr-2" :class="stat.status ? 'btn-ghost' : 'bg-pri bd-pri text-white'" @click="onUpdateStatus(stat.id)">{{stat.status ? 'Ẩn' : 'Hiện'}}</button>
                <button class="btn btn-sm btn-ghost mr-2" @click="onDelete(stat.id)">Xóa</button>
              </td>
            </tr>
          </tbody>
          <tbody
            v-if="loading"
            class="h-100"
            style="min-height: 600px;"
          >
            <tr>
              <td
                colspan="6"
                class="text-center py-5"
              >
                <div
                  class="spinner-border"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <Pagination
          class="Content"
          v-show="stats && stats.count"
          v-if="stats"
          :items="stats"
          :current_page="current_page"
          @onRefresh="getStats"
        ></Pagination>
      </div>
    </div>
    <ModalCreateStats @onCreate="onCreate"/>
    <ModalUpdateStats @onUpdate="onUpdate" :edit="edit"/>
  </div>
</template>

<script>
import Pagination from '../../../components/Pagination'
import ModalCreateStats from '../../../components/Stats/ModalCreateStats.vue'
import ModalUpdateStats from '../../../components/Stats/ModalUpdateStats.vue'
export default {
  name: 'StatsList',
  components: { Pagination, ModalCreateStats, ModalUpdateStats },
  data () {
    return {
      stats: [],
      loading: false,
      search: '',
      sort: 'created_at',
      sort_by: 'desc',
      current_page: 1,
      edit: null
    }
  },
  mounted () {
    let self = this
    self.getStats()
  },
  methods: {
    goToDetail (stat) {
      this.$router.push({path: `/admin/diseases-templates/${stat.id}/show`})
    },
    async getStats () {
      let self = this
      self.loading = true
      try {
        self.current_page = parseInt(self.$route.query.page) || 1
        let params = {
          limit: 10,
          search: self.search,
          sort: self.sort || 'created_at',
          sort_by: self.sort_by || 'desc',
          page: self.current_page
        }
        await self.$rf.getRequest('AdminRequest').getSurvivalStats(params).then(res => {
          if (res && res.data) {
            self.stats = res.data
          }
        })
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    async onCreate (data) {
      let self = this
      if (data) {
        self.$rf.getRequest('AdminRequest').createSurvivalStats(data).then((resp) => {
          self.search = ''
          self.sort = 'created_at'
          self.sort_by = 'desc'
          self.current_page = 1
          self.showAdd(false)
          self.getStats()
        })
      }
    },
    async onUpdate (data) {
      let self = this
      if (data) {
        self.$rf.getRequest('AdminRequest').updateSurvivalStats(self.edit.id, data).then((resp) => {
          self.showEdit(false)
          self.edit = null
          self.getStats()
        })
      }
    },
    async onUpdateStatus (id) {
      let self = this
      let r = confirm('Bạn có chắc chắn muốn ẩn chỉ số này khỏi danh sách chọn? Chỉ số này sẽ vẫn hiển thị tại các nơi đã được sử dụng.')
      if (!r) return
      if (id) {
        self.$rf.getRequest('AdminRequest').updateSurvivalStatStatus(id).then((resp) => {
          self.getStats()
        })
      }
    },
    async onDelete (id) {
      let self = this
      let r = confirm('Bạn có chắc chắn muốn xóa chỉ số này khỏi danh sách chọn? Chỉ số này sẽ không thể xóa được nếu đã được sử dụng.')
      if (!r) return
      if (id) {
        self.$rf.getRequest('AdminRequest').deleteSurvivalStats(id).then((resp) => {
          self.getStats()
        })
      }
    },
    onSearch () {
      let self = this
      self.getStats()
    },
    onSearchDelete () {
      let self = this
      if (self.search.length === 0) {
        self.getStats()
      }
    },
    formatDDMMYYYY (date) {
      return window.moment(date).format('HH:mm DD/MM/YY')
    },
    onEdit (stat) {
      this.edit = stat
      this.showEdit(true)
    },
    showEdit (show) {
      window.$('#ModalUpdateStats').modal(show ? 'show' : 'hide')
    },
    showAdd (show) {
      window.$('#ModalCreateStats').modal(show ? 'show' : 'hide')
    }
  }
}
</script>

<style scoped>
</style>