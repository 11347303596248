import { render, staticRenderFns } from "./RequestListAdmin.vue?vue&type=template&id=10f6df73&scoped=true"
import script from "./RequestListAdmin.vue?vue&type=script&lang=js"
export * from "./RequestListAdmin.vue?vue&type=script&lang=js"
import style0 from "./RequestListAdmin.vue?vue&type=style&index=0&id=10f6df73&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10f6df73",
  null
  
)

export default component.exports