<template>
  <div class="container pt-5">
    <div v-show="add">
      <p class="fs-24 fw-600">Thêm sản phẩm</p>
      <div class="mb-3">
        <label class="form-label required">Tên sản phẩm</label>
        <input
          type="text"
          class="form-control border"
          name="example-required-input"
          placeholder="Required..."
        />
      </div>
      <div class="mb-3">
        <label class="form-label required">Đơn giá</label>
        <input
          type="number"
          class="form-control border"
          name="example-required-input"
          placeholder="Required..."
        />
      </div>
      <div class="mb-3">
        <label class="form-label required">Đơn vị tính</label>
        <input
          type="text"
          class="form-control border"
          name="example-required-input"
          placeholder="Required..."
        />
      </div>
      <div class="mb-3">
        <label class="form-label">Mô tả</label>
        <textarea
          class="form-control border"
          name="example-textarea-input"
          rows="6"
          placeholder="Content.."
        ></textarea>
      </div>
      <div class="mb-3">
        <div class="form-label">Ảnh đại diện</div>
        <input type="file" class="form-control" />
      </div>
    </div>
    <div v-if="showing_shop" v-show="list">
      <p class="fs-24 fw-600 cursor-pointer" @click="backToShops">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.2071 16.2071C14.5976 15.8166 14.5976 15.1834 14.2071 14.7929L11.4142 12L14.2071 9.20711C14.5976 8.81658 14.5976 8.18342 14.2071 7.79289C13.8166 7.40237 13.1834 7.40237 12.7929 7.79289L9.29289 11.2929C8.90237 11.6834 8.90237 12.3166 9.29289 12.7071L12.7929 16.2071C13.1834 16.5976 13.8166 16.5976 14.2071 16.2071Z" fill="#8F90A6"/>
</svg>

Danh sách sản phẩm</p>
        <div class="card">
                  <div class="table-responsive">
                    <table class="table table-vcenter card-table">
                      <thead>
                        <tr>
                          <th class="pl-3">Thông tin</th>
                          <th>Nhà cung cấp</th>
                          <th>Mô tả</th>
                          <th class="w-90px"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="p in products" :key="p.name">
                          <td class="pl-3"  data-label="Thông tin">
                            <div class="d-flex py-1 align-items-center">
                              <span class="avatar mr-2" :style="'background-image: url('+ p.img+')'"></span>
                              <div class="flex-fill">
                                <div class="font-weight-medium max-line-1">{{p.name}}</div>
                              </div>
                            </div>
                          </td>
                          <td data-label="Nhà cung cấp">
                            <div class="text-muted max-line-1">{{showing_shop.name}}</div>
                          </td>
                          <td data-label="Mô tả">
                            <div class="text-muted max-line-1">{{p.desc}}</div>
                          </td>
                          <td class="w-90px">
                            <a href="javascript:;" class="fs-16 fw-500 txt-pri">Gỡ bỏ</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="card-footer d-flex justify-content-between align-items-center">
                    <p class="m-0 text-muted">Showing <span>1</span> to <span>4</span> of <span>16</span> entries</p>
                    <ul class="pagination m-0 ms-auto">
                      <li class="page-item disabled">
                        <a class="page-link" href="#" tabindex="-1" aria-disabled="true">
                          <!-- Download SVG icon from http://tabler-icons.io/i/chevron-left -->
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="15 6 9 12 15 18"></polyline></svg>
                          prev
                        </a>
                      </li>
                      <li class="page-item active"><a class="page-link" href="#">1</a></li>
                      <li class="page-item "><a class="page-link" href="#">2</a></li>
                      <li class="page-item"><a class="page-link" href="#">3</a></li>
                      <li class="page-item"><a class="page-link" href="#">4</a></li>
                      <li class="page-item"><a class="page-link" href="#">5</a></li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          next <!-- Download SVG icon from http://tabler-icons.io/i/chevron-right -->
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="9 6 15 12 9 18"></polyline></svg>
                        </a>
                      </li>
                    </ul>
                  </div>
    </div>
    <div v-show="shop">
      <p class="fs-24 fw-600">Nhà cung cấp</p>
        <div class="card">
                  <div class="table-responsive">
                    <table class="table table-vcenter card-table table-bordered">
                      <thead>
                        <tr>
                          <th class="pl-3">Thông tin</th>
                          <th>Mô tả</th>
                          <th class="w-1"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="s in shops" :key="s.name" class="cursor-pointer" @click="showShop(s)">
                          <td class="pl-3">
                            <div class="d-flex py-1 align-items-center">
                              <div class="flex-fill">
                                <div class="font-weight-medium max-line-1">{{s.name}}</div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="text-muted max-line-1">{{s.desc}}</div>
                          </td>
                          <td>
                            <!-- <a href="#">Xóa</a> -->

                            <button class="btn border bd-pri radius-10">
                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="m-0">
<path d="M10.4997 5.41667C13.658 5.41667 16.4747 7.19167 17.8497 10C16.4747 12.8083 13.6663 14.5833 10.4997 14.5833C7.33301 14.5833 4.52467 12.8083 3.14967 10C4.52467 7.19167 7.34134 5.41667 10.4997 5.41667ZM10.4997 3.75C6.33301 3.75 2.77467 6.34167 1.33301 10C2.77467 13.6583 6.33301 16.25 10.4997 16.25C14.6663 16.25 18.2247 13.6583 19.6663 10C18.2247 6.34167 14.6663 3.75 10.4997 3.75ZM10.4997 7.91667C11.6497 7.91667 12.583 8.85 12.583 10C12.583 11.15 11.6497 12.0833 10.4997 12.0833C9.34967 12.0833 8.41634 11.15 8.41634 10C8.41634 8.85 9.34967 7.91667 10.4997 7.91667ZM10.4997 6.25C8.43301 6.25 6.74967 7.93333 6.74967 10C6.74967 12.0667 8.43301 13.75 10.4997 13.75C12.5663 13.75 14.2497 12.0667 14.2497 10C14.2497 7.93333 12.5663 6.25 10.4997 6.25Z" fill="#20409B"/>
</svg>
                            </button>

                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="card-footer d-flex justify-content-between align-items-center">
                    <p class="m-0 text-muted">Showing <span>1</span> to <span>4</span> of <span>16</span> entries</p>
                    <ul class="pagination m-0 ms-auto">
                      <li class="page-item disabled">
                        <a class="page-link" href="#" tabindex="-1" aria-disabled="true">
                          <!-- Download SVG icon from http://tabler-icons.io/i/chevron-left -->
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="15 6 9 12 15 18"></polyline></svg>
                          prev
                        </a>
                      </li>
                      <li class="page-item active"><a class="page-link" href="#">1</a></li>
                      <li class="page-item "><a class="page-link" href="#">2</a></li>
                      <li class="page-item"><a class="page-link" href="#">3</a></li>
                      <li class="page-item"><a class="page-link" href="#">4</a></li>
                      <li class="page-item"><a class="page-link" href="#">5</a></li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          next <!-- Download SVG icon from http://tabler-icons.io/i/chevron-right -->
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="9 6 15 12 9 18"></polyline></svg>
                        </a>
                      </li>
                    </ul>
                  </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductManage',
  data () {
    return {
      img: '',
      add: false,
      list: false,
      shop: true,
      products: [
        {img: '/assets/images/Products/1.png', name: 'Sữa RECOVA cho người bệnh Ung Thư 400g', price: 1000000, dvt: 'hộp', desc: 'Sữa RECOVA...'},
        {img: '/assets/images/Products/2.png', name: 'Viên uống Vitamin C 500mg Nature\'s Bounty', price: 1050000, dvt: 'hộp', desc: 'Viên uống Vitamin C...'},
        {img: '/assets/images/Products/3.png', name: 'Sữa FortiCare vị Cappuccino (Dành cho người bệnh ung thư) ', price: 300000, dvt: 'hộp', desc: ''},
        {img: '/assets/images/Products/4.png', name: 'Combo dinh dưỡng dành cho người ung thư vị ung, bác sĩ tại H&H khuyên dùng', price: 2200000, dvt: 'hộp', desc: ''}
      ],
      shops: [
        {id: 1, name: 'Công ty cổ phần Traphaco', desc: 'Công ty cổ phần Traphaco là công ty dược nổi tiếng của Việt Nam. Trong suốt gần 50 hoạt động, công ty vinh dự nằm trong các hạng mục danh giá như: Traphaco lần thứ 2 liên tiếp đạt Top 10 công ty uy tín nhất ngành Dược Việt Nam, Thuốc bổ gan Boganic lần thứ 2 liên tiếp lọt “Top 10 Sản phẩm thương hiệu Việt xuất sắc”, Traphaco - Top 50 doanh nghiệp có thương hiệu nhà tuyển dụng hấp dẫn nhất Việt Nam.. .'},
        {id: 2, name: 'Công ty cổ phần PYMEPHARCO', desc: 'Với phương châm chính sách chất lượng cao, ổn định, đồng nhất và hướng tới hiệu quả tối ưu, PYMEPHARCO đã đầu tư trang bị hệ thống máy móc hiện đại và công nghệ tiên tiến, cũng như tập trung một lực lượng cán bộ khoa học có năng lực và trình độ chuyên môn cao'},
        {id: 3, name: 'Công ty CP Dược phẩm ImexPharm', desc: 'Được thành lập từ năm 1977 với tiền thân là Công ty dược cấp II, trải qua nhiều khó khăn, thách thức, Công ty CP Dược phẩm Imexpharm đến nay đã trở thành một trong 10 doanh nghiệp cung cấp dược phẩm uy tín nhất trên thị trường nước ta.'},
        {id: 4, name: 'Công ty cổ phần dược phẩm thương mai ABC', desc: '(+84)0345 - 900905, T8, ACCI Building, 210 Lê Trọng Tấn'}
      ],
      showing_shop: null
    }
  },
  methods: {
    showShop (s) {
      this.showing_shop = s
      this.shop = false
      this.list = true
    },
    backToShops () {
      this.showing_shop = null
      this.shop = true
      this.list = false
    }
  }
}
</script>

<style scoped>
.w-90px {
    width: 90px;
}
</style>