<template>
	<div class="row">
		<div class="telemedicine-management pr-3">
			<div class="row pl-3">
				<div class="col-12">
					<div class="d-flex py-1 align-items-center">
						<img src="../../../public/assets/images/icon/icon_contact_blue.svg" alt="" class="mr-2"><span class="txt-pri page-title">Bệnh nhân</span>
					</div>
				</div>
				<div class="col-sm-12">
					<div class="row justify-content-lg-between">
            <div class="col-12 col-lg-auto mb-1">
              <div class="d-flex">
              </div>
            </div>
						<div class="col-12 col-lg-auto mb-1">
							<div class="d-flex">
									<input class="form-control mr-2 bg-white" type="text" name="" v-model="search" @keyup.delete="searchStrDelete()" @keyup.enter="searchStr()"> <button class="btn bg-pri bd-pri text-white" @click="searchStr()">Tìm kiếm</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card radius-10 pt-2 pl-4 pr-4 border-0">
				<div class="table-responsive">
					<table class="table table-vcenter table-mobile-md card-table table-scroll">
						<thead>
							<tr>
								<th>Thông tin bệnh nhân</th>
								<th>Địa chỉ</th>
								<th>HODO</th>
								<th>Created at</th>
                <th>Last login</th>
                <th>Note</th>
							</tr>
						</thead>
						<tbody v-if="persons && persons.count && persons.data" class="h-100">
							<tr v-for="person in persons.data" :key="person.id">
								<td data-label="Thông tin bệnh nhân" class="table-td-name-avatar">
									<div class="d-flex py-1 align-items-center">
										<div style="min-width: 40px; cursor: pointer;" @click="getPersonDetail(person.id)">
											<span v-if="hasAvatar(person.avatar)" class="avatar avatar-sm avatar-rounded" :style="'background-image: url('+getImageURL(person.avatar)+')'"></span>
											<span v-if="!hasAvatar(person.avatar)" class="avatar avatar-sm avatar-rounded bg-secondary text-white">
												{{ person && person.name ? person.name.charAt(0) : '' }}
											</span>
										</div>
										<div class="flex-fill" @click="getPersonDetail(person.id)" style="cursor: pointer;">
											<p class="p-0 m-0">{{person && person.name ? person.name : ''}}</p>
                      <small class="robo-12-400 text-black-50">{{person && person.phone ? person.phone : ''}}</small>
										</div>
									</div>
								</td>
								<td>{{person ? person.address ? person.address : '' : ''}}</td>
								<td><span  class="robo-16-500" :class="person && person.user ? 'txt-pri' : 'text--red'">{{person && person.user ? 'HODO' : 'Chưa cài app'}}</span></td>
								<td v-html="person ? person.created_at ? formatWeekdayTime(person.created_at)  : '' : ''"></td>
                <td v-html="person && person.user && person.user.last_login ? formatWeekdayTime(person.user.last_login)  : ''"></td>
                <td @click="addNote(person.id)" style="cursor: pointer;"><span class="txt-pri" style="text-decoration: underline;">Xem</span></td>
							</tr>
						</tbody>
					</table>
					<Pagination v-show="persons && persons.count" v-if="persons" :items="persons" :current_page="current_page" @onRefresh="getPersons"></Pagination>
				</div>
				<div v-if="persons && !persons.count && !loading" class="row">
					<div class="col-sm-4 offset-sm-4 pt-3 pb-5 text-center">
						<img src="../../../public/assets/images/not-found-search.png" class="img-responsive p-0" alt="">
						<p class="robo-16-400 text-black">{{search ? 'HODO không tìm thấy bệnh nhân mà bạn tìm' : 'Chưa có bệnh nhân nào!' }}</p>
					</div>
				</div>
			</div>
		</div>
    <ModelNotePersons @onSubmitNoteUpdate="onSubmitNoteUpdate" @onSubmitNote="onSubmitNote" :note_edit="note_edit"></ModelNotePersons>
	</div>
</template>
<script>
  import appUtils from '../../utils/appUtils'
  import Pagination from '../../components/Pagination'
  import ModelNotePersons from '../../components/Cms/ModelNotePersons'
  export default {
    name: 'PersonAdminListing',
    components: {Pagination, ModelNotePersons},
    data () {
      return {
        persons: null,
        loading: false,
        params: {
          limit: 10,
          search: '',
          sort: 'created_at',
          sort_by: 'desc'
        },
        note_edit: '',
        search: '',
        current_page: 0,
        page: 1,
        person_id: 0
      }
    },
    mounted () {
      this.page = this.$route.query.page ? this.$route.query.page : 1
      this.getPersons()
    },
    watch: {
      '$route.query.type': {
        handler: function () {
          let self = this
          self.page = self.$route.query.page
          self.getPersons()
        },
        deep: true
      }
    },
    methods: {
      async getPersons () {
        try {
          let self = this
          self.current_page = parseInt(self.$route.query.page) || 1
          self.params = {
            limit: 10,
            search: self.search,
            sort: 'created_at',
            sort_by: 'desc',
            page: self.current_page
          }
          const result = await self.$rf.getRequest('AdminRequest').getPersons(self.params)
          self.persons = result.data
          self.current_page = result.data.current_page
        } catch (e) {
          console.log(e)
        } finally {

        }
      },
      hasAvatar (person) {
        return !!(person && person.avatar)
      },
      getImageURL (avatar) {
        return appUtils.getImageURL(avatar)
      },
      formatWeekdayTimev2 (date_time) {
        let curr_week = window.moment().week()
        let date_time_week = window.moment(date_time).week()
        if (curr_week === date_time_week) {
          return window.moment(date_time).fromNow()
        } else {
          var split_time = window.moment(date_time).format('DD/MM/YYYY HH:mm ').split(' ')
          var str_time = split_time[1] + '<br><small class="robo-12-400 text-muted">' + split_time[0] + '</small>'
          return str_time
        }
      },
      formatWeekdayTime (date_time) {
        var split_time = window.moment(date_time).format('DD/MM/YYYY HH:mm ').split(' ')
        var str_time = split_time[1] + '<br><small class="robo-12-400 text-muted">' + split_time[0] + '</small>'
        return str_time
      },
      searchStr () {
        let self = this
        self.$route.query.page = 1
        self.getPersons()
        self.$router.push({name: self.$router.currentRoute.name, query: {page: self.page}}).catch(() => {})
      },
      searchStrDelete () {
        let self = this
        if (self.search.length === 0) {
          self.$route.query.page = 1
          self.getPersons()
          self.$router.push({name: self.$router.currentRoute.name, query: {page: self.page}})
        }
      },
      getPersonDetail (person_id) {
        let self = this
        self.$router.push({path: '/admin/person/' + person_id + '/detail?type=person-info'})
      },
      onShowModalNote (show) {
        window.$('#modal--note').modal(show ? 'show' : 'hide')
      },
      async addNote (id_person) {
        let self = this
        try {
          var params = {}
          params.person_id = id_person
          self.person_id = id_person
          self.$rf.getRequest('AdminRequest').getPersonsNotes(params).then(req => {
            if (req && req.data && req.data.data && req.data.data[0]) {
              self.note_edit = req.data.data[0]
            } else {
              self.note_edit = ''
            }
            self.onShowModalNote(true)
          })
        } catch (e) {
          console.log(e)
        }
      },
      async onSubmitNoteUpdate (data) {
        let self = this
        try {
          await self.$rf.getRequest('AdminRequest').postPersonsNotesEdit(data.id, data)
        } catch (e) {
          console.log(e)
        } finally {
          self.onShowModalNote(false)
        }
      },
      async onSubmitNote (data) {
        let self = this
        try {
          data.person_id = self.person_id
          await self.$rf.getRequest('AdminRequest').postPersonsNotes(data)
        } catch (e) {
          console.log(e)
        } finally {
          self.onShowModalNote(false)
        }
      }
    }
  }
</script>
<style scoped></style>